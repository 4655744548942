import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import axios from "axios";
import LoadingComponent from "./components/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { userAuth } from "./redux/action/loginAuth";
import { BASE_URL } from "./lib/api";
import "./assets/scss/style.scss";

const HomeDefault = React.lazy(() => import("./pages/HomeDefault"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const ForgetPasswrd = React.lazy(() => import("./pages/ForgetPasswrd"));
const Refer = React.lazy(() => import("./pages/Refer"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogDetails = React.lazy(() => import("./pages/BlogDetails"));
const PricePage = React.lazy(() => import("./pages/PricePage"));
const Signal = React.lazy(() => import("./pages/Signal"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const TermsandConditions = React.lazy(() =>
  import("./pages/TermsandConditions")
);
const ReturnAndRefundPolicy = React.lazy(() =>
  import("./pages/ReturnAndRefundPolicy")
);
const CookiesPolicy = React.lazy(() => import("./pages/CookiesPolicy"));
const AffiliatePolicy = React.lazy(() => import("./pages/AffiliatePolicy"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Faq = React.lazy(() => import("./pages/Faq"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const Contact = React.lazy(() => import("./elements/contact/Contact"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const Error = React.lazy(() => import("./pages/Error"));
// const Register = React.lazy(() => import("./pages/Register(Not Used)"));

const App = () => {
  const dispatch = useDispatch();
  const loginAuth = useSelector((state) => state.loginAuth);

  useEffect(() => {
    const userToken = localStorage.getItem("usertoken");
    const axiosConfig = {
      method: "get",
      url: `${BASE_URL}/forex/user/loggeduser`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/json",
      },
    };

    axios(axiosConfig)
      .then((res) => {
        dispatch(
          userAuth({
            loginAuth: true,
            token: userToken,
            userData: res.data.user,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);

  return (
    <BrowserRouter>
      <PageScrollTop>
        <Routes>
          <Route
            path={`${process.env.PUBLIC_URL}/`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <HomeDefault />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/price`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <PricePage />
              </Suspense>
            }
          />
          {loginAuth && (
            <Route
              path={`${process.env.PUBLIC_URL}/signal`}
              exact
              element={
                <Suspense fallback={<LoadingComponent />}>
                  <Signal />
                </Suspense>
              }
            />
          )}
          <Route
            path={`${process.env.PUBLIC_URL}/affilate-program`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <Refer />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/privacy-policy`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <TermsandConditions />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/cancellation-and-refund-policy`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <ReturnAndRefundPolicy />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/cookies-policy`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <CookiesPolicy />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/affiliate-policy`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <AffiliatePolicy />
              </Suspense>
            }
          />
          {loginAuth && (
            <Route
              path={`${process.env.PUBLIC_URL}/profile`}
              exact
              element={
                <Suspense fallback={<LoadingComponent />}>
                  <Profile />
                </Suspense>
              }
            />
          )}
          <Route
            path={`${process.env.PUBLIC_URL}/faq`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <Faq />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/forget-passwrd`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <ForgetPasswrd />
              </Suspense>
            }
          />

          <Route
            path={`${process.env.PUBLIC_URL}/change-password`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <ChangePassword />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/blog`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <Blog />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/blog-details/:blog_slug`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <BlogDetails />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/contact-us`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/checkout`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <Checkout />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/about-us`}
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path="*"
            exact
            element={
              <Suspense fallback={<LoadingComponent />}>
                <Error />
              </Suspense>
            }
          />
        </Routes>
      </PageScrollTop>
    </BrowserRouter>
  );
};

export default App;
