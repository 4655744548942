export const BASE_URL = "https://admin.thegenerativeaitools.com/api";
export const IMAGE_BASE_URL = "https://admin.thegenerativeaitools.com/";

export const businessApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/business/setting`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const websiteSettingApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/website/setting`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const homeApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/home`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const calltoactionApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/calltoaction`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const aboutApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/about`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const testimonialApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/testimonial`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const faqsApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/faqs`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const blogApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/blog`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const signalApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/signal`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const howItWorkApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/how-it-works`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const packageApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/package`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const howItSignupApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/how-it-signup`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const allHeadingApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/all-heading`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const referApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/refer`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
// export const referalWalletApi = async () => {
//   try {
//     const response = await fetch(`${BASE_URL}/user/refer/point`);
//     if (!response.ok) {
//       throw new Error("Network response was not ok");
//     }
//     return await response.json();
//   } catch (error) {
//     console.error("Fetch error:", error);
//     throw error;
//   }
// };

export const razorpayApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/razorpay`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const adsApi = async () => {
  try {
    const response = await fetch(`${BASE_URL}/ads`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};


